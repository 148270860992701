import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Index from 'pages/HomePage';
import NotFound from 'pages/NotMatch';

import AboutHistory from 'pages/AboutUs/History';
import AboutRegulation from 'pages/AboutUs/Regulation';

import TraderCentAccount from 'pages/Trader/CentAccount';
import TraderStandardAccount from 'pages/Trader/StandardAccount';
import TraderComparisonAccount from 'pages/Trader/ComparisonAccount';
import TraderContractSpecification from 'pages/Trader/ContractSpecification';
import TraderDepositWays from 'pages/Trader/DepositWays';
import TraderWithdrawalWays from 'pages/Trader/WithdrawalWays';

import AgentRegionalManager from 'pages/Agent/RegionalManager';
import AgentMasterIB from 'pages/Agent/MasterIB';
import AgentIB from 'pages/Agent/IB';
import AgentMultiLevel from 'pages/Agent/MultiAgent';
import AgentRealtimeCommission from 'pages/Agent/RealtimeCommission';
import AgentMinimumTrade from 'pages/Agent/MinimumTrade';
import AgentRoomDashboard from 'pages/Agent/RoomDashboard';
import AgentCommissionReport from 'pages/Agent/CommissionReport';
import AgentAffiliateClientReport from 'pages/Agent/AffiliateClientReport';

import PlatformMT4PC from 'pages/Platform/MT4PC';
import PlatformMT4Mobile from 'pages/Platform/MT4Mobile';
import PlatformOvalApps from 'pages/Platform/OvalCapitalApps';

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Index {...props} />}
          />
          <Route
            exact
            path="/about/history"
            render={(props) => <AboutHistory {...props} />}
          />
          <Route
            exact
            path="/about/regulation"
            render={(props) => <AboutRegulation {...props} />}
          />
          <Route
            exact
            path="/trader/cent-account"
            render={(props) => <TraderCentAccount {...props} />}
          />
          <Route
            exact
            path="/trader/standard-account"
            render={(props) => <TraderStandardAccount {...props} />}
          />
          <Route
            exact
            path="/trader/comparison-account"
            render={(props) => <TraderComparisonAccount {...props} />}
          />
          <Route
            exact
            path="/trader/contract-specification"
            render={(props) => (
              <TraderContractSpecification {...props} />
            )}
          />
          <Route
            exact
            path="/trader/deposit-ways"
            render={(props) => <TraderDepositWays {...props} />}
          />
          <Route
            exact
            path="/trader/withdrawal-ways"
            render={(props) => <TraderWithdrawalWays {...props} />}
          />
          <Route
            exact
            path="/agent/regional-manager"
            render={(props) => <AgentRegionalManager {...props} />}
          />
          <Route
            exact
            path="/agent/master-ib"
            render={(props) => <AgentMasterIB {...props} />}
          />
          <Route
            exact
            path="/agent/ib"
            render={(props) => <AgentIB {...props} />}
          />
          <Route
            exact
            path="/agent/multi-level-agent"
            render={(props) => <AgentMultiLevel {...props} />}
          />
          <Route
            exact
            path="/agent/realtime-commission"
            render={(props) => <AgentRealtimeCommission {...props} />}
          />
          <Route
            exact
            path="/agent/minimum-trade-time"
            render={(props) => <AgentMinimumTrade {...props} />}
          />
          <Route
            exact
            path="/agent/room-dashboard"
            render={(props) => <AgentRoomDashboard {...props} />}
          />
          <Route
            exact
            path="/agent/commission-report"
            render={(props) => <AgentCommissionReport {...props} />}
          />
          <Route
            exact
            path="/agent/agent-client-report"
            render={(props) => (
              <AgentAffiliateClientReport {...props} />
            )}
          />
          <Route
            exact
            path="/platform/mt4-pc"
            render={(props) => <PlatformMT4PC {...props} />}
          />
          <Route
            exact
            path="/platform/mt4-mobile"
            render={(props) => <PlatformMT4Mobile {...props} />}
          />
          <Route
            exact
            path="/platform/oval-capital-apps"
            render={(props) => <PlatformOvalApps {...props} />}
          />
          <Route component={NotFound} />
        </Switch>
      </Switch>
    </BrowserRouter>
  );
}
