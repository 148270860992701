import React from 'react';

// core components
import TopNavbar from 'components/Navbars/TopNavbar';
import HomeCard from 'components/Home/Card';
import OurService from 'components/Home/OurService';
import ButtonGreen from 'components/Button/ButtonGreen';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';
import Carousel from 'components/Home/Carousel';
import FixedNavbar from 'components/Navbars/FixedNavbar';

export default function HomePage() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="feature-carousel">
            <Carousel />
          </div>

          <div className="feature-card-infos">
            <HomeCard />
          </div>

          <div className="feature-button-register">
            <ButtonGreen />
          </div>

          <div className="feature-banner-info mt-5 bg-gray-300">
            <section className="text-gray-900 body-font container">
              <div className="container px-5 py-12 mx-auto flex flex-wrap items-center">
                <div className="lg:w-5/5 md:w-2/2 md:pr-16 lg:pr-0 pr-0">
                  <div className="w-full text-center">
                    <h1 className="items-center title-font font-medium text-3xl text-gray-900">
                      Oval Capital International
                    </h1>
                  </div>

                  <p className="leading-relaxed mt-4 text-justify">
                    <b>Oval Capital International</b> is the leading
                    brokerage company that establish since Feb 2014,
                    our company business license is in Hongkong, Oval
                    Capital International has provided Market Trading
                    for 6+ Years.
                  </p>
                  <p className="leading-relaxed mt-4 text-justify">
                    We have experience working with professional
                    traders even newbie traders. Our market is
                    designed without any interruption for you to make
                    transactions, and you can transact directly to the
                    Market.
                  </p>
                </div>
              </div>
            </section>
          </div>

          <div className="feature-our-service">
            <OurService />
          </div>

          <div className="feature-button-register">
            <ButtonGreen />
          </div>

          <div className="feature-payment-list mt-5">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
