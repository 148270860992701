import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AgentAffiliateClientReport() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-master-ib">
            <div className="container py-5 text-center">
              <h1 className="font-bold font-serif text-xl text-dark">
                Facility Agent of Oval Capital International
              </h1>
            </div>

            <div className="container flex justify-center">
              <img
                src={require(`../../assets/images/logos/admin-dashboard.png`)}
                alt="Visa Mastercard"
                className="h-auto"
              />
            </div>

            <div className="container lg:px-5 lg:py-5">
              <div className="p-2">
                <p className="text-center text-dark">
                  We prepare detailed reports for your agency, to
                  support the development and analysis of the strategy
                  you are doing
                </p>
              </div>

              <div className="text-gray-700 body-font overflow-hidden">
                <div className="container lg:px-5 py-10 mx-auto">
                  <div className="flex flex-wrap -m-4 justify-center">
                    {MyData.map((item, index) => (
                      <MyCard
                        key={index}
                        images={item.icon}
                        name={item.name}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

const MyData = [
  {
    name: 'Agent Room Dashboard',
    icon: 'report-admin-dashboard.jpg',
  },
  {
    name: 'Commission Report',
    icon: 'report-commission-report.png',
  },
  {
    name: 'Agent Report',
    icon: 'report-agent-report.jpg',
  },
];

const MyCard = ({ images, name }) => {
  return (
    <div className="transform hover:-translate-y-1 hover:scale-101 p-4 xl:w-4/12 md:w-2/12 w-full">
      <div className="h-full p-4 rounded-lg border shadow-md flex flex-col relative overflow-hidden">
        <div className="text-center text-lg">
          <h1>
            <b>{name}</b>
          </h1>
        </div>

        <div className="flex justify-center pt-2">
          <img
            src={require(`../../assets/images/icons/${images}`)}
            alt="Visa Mastercard"
            className="h-40 pt-5"
          />
        </div>
      </div>
    </div>
  );
};
