import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// styles for now ui
import 'assets/css/bootstrap.min.css';
import 'assets/scss/now-ui-kit.scss?v=1.4.0';

// styles for tailwind css
import 'styles/main.css';
import 'styles/tailwind.css';

import MainRoutes from './routes/MainRoutes';

ReactDOM.render(
  <React.Fragment>
    <MainRoutes />
  </React.Fragment>,
  document.getElementById('root'),
);

serviceWorker.register();
