import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function TraderComparisonAccount() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="feature-account-comparison">
            <div className="container px-5 py-5">
              <div className="text-center">
                <h1 className="font-bold text-xl text-dark">
                  Comparison Account
                </h1>
              </div>
            </div>

            <div className="container lg:px-5 lg:py-5">
              <section className="text-gray-700 body-font overflow-hidden">
                <div className="container lg:px-5 py-10 mx-auto">
                  <div className="flex flex-wrap -m-4 justify-center">
                    <div className="transform hover:-translate-y-1 hover:scale-101 p-4 xl:w-4/12 md:w-2/12 w-full">
                      <div className="h-full p-4 sm:p-2 rounded-lg border shadow-md flex flex-col relative overflow-hidden">
                        <h2 className="text-sm text-center font-semibold tracking-widest title-font mb-1">
                          Cent Account
                        </h2>
                        <hr className="my-3" />
                        <table className="text-md rounded mb-4">
                          <tbody>
                            <MyTable
                              title={'Minimum Deposit'}
                              text={': $1'}
                              className="w-full h-full"
                            />
                            <MyTable
                              title={'Maximum Laverage'}
                              text={': 1 : 1000'}
                            />
                            <MyTable
                              title={'Spread Start From'}
                              text={': 1 Pip'}
                            />
                            <MyTable
                              title={'Maximum Open Trade'}
                              text={': 5 Lot Standard'}
                            />
                            <MyTable
                              title={'Trading All Instrument'}
                              text={': Yes'}
                            />
                            <MyTable
                              title={'Type of Execution'}
                              text={': Market Execution'}
                            />
                          </tbody>
                        </table>
                        <div className="w-full items-center justify-center flex">
                          <button className="hvr-shutter-out-horizontal mt-auto text-white bg-inverse border-0 py-2 px-4 w-1/2 sm:w-2/2 focus:outline-none rounded-full">
                            Open Account
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="transform hover:-translate-y-1 hover:scale-101 p-4 xl:w-4/12 md:w-2/12 w-full">
                      <div className="h-full p-4 rounded-lg border shadow-md flex flex-col relative overflow-hidden">
                        <h2 className="text-sm text-center font-semibold tracking-widest title-font mb-1">
                          Standard Account
                        </h2>
                        <hr className="my-3" />
                        <table className="text-md rounded mb-4">
                          <tbody>
                            <MyTable
                              title={'Minimum Deposit'}
                              text={': $5'}
                              className="w-full h-full"
                            />
                            <MyTable
                              title={'Maximum Laverage'}
                              text={': 1 : 400'}
                            />
                            <MyTable
                              title={'Spread Start From'}
                              text={': 1 Pip'}
                            />
                            <MyTable
                              title={'Maximum Open Trade'}
                              text={': 5 Lot Standard'}
                            />
                            <MyTable
                              title={'Trading All Instrument'}
                              text={': Yes'}
                            />
                            <MyTable
                              title={'Type of Execution'}
                              text={': Market Execution'}
                            />
                          </tbody>
                        </table>
                        <div className="w-full items-center justify-center flex">
                          <button className="hvr-shutter-out-horizontal mt-auto text-white bg-inverse border-0 py-2 px-4 w-1/2 focus:outline-none rounded-full">
                            Open Account
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

const MyTable = ({ title, text }) => {
  return (
    <tr className="w-full">
      <td className="p-1 px-1">{title}</td>
      <td className="p-1 px-1">{text}</td>
    </tr>
  );
};
