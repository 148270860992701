import React from 'react';

export default function ButtonGreen() {
  return (
    <div className="py-2 px-5 space-y-2">
      <div className="flex justify-center">
        <button className="rounded-full hvr-shutter-out-horizontal flex mx-auto mt-1 text-white bg-inverse border-0 py-2 px-8 focus:outline-none text-lg">
          Register Now!
        </button>
      </div>
    </div>
  );
}
