import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import ButtonGreen from 'components/Button/ButtonGreen';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AboutHistory() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-history">
            <section className="text-gray-700 body-font">
              <div className="container px-5 lg:py-10">
                <div className="lg:container lg:px-5">
                  <h1 className="text-lg text-bold text-black">
                    <b>About Us - History</b>
                  </h1>
                </div>
              </div>

              <div className="container px-5 py-10 mx-auto flex">
                <div className="lg:w-12/12 mx-auto">
                  <div className="flex flex-col sm:flex-row">
                    <div className="sm:w-1/3 md:w-3/3 text-center sm:pr-8 sm:py-8">
                      <div className="flex flex-col items-center text-center justify-center">
                        <img
                          src={require('../../assets/images/logos/oval-capital-small.png')}
                          alt="Oval Capital International Small"
                          className="h-48"
                        />
                      </div>
                    </div>
                    <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-300 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-left sm:text-left">
                      <h2 className="text-lg text-bold text-dark">
                        <b>Who we are</b>
                      </h2>
                      <br />
                      <p className="leading-relaxed text-lg mb-4">
                        <b>Oval Capital International</b> is an award
                        winning forex and commodities broker,
                        providing trading services and facilities to
                        both retail and institutional clients. Thro
                        ugh its policy of providing the best possible
                        trading conditions to its clients and allowing
                        both scalpers and traders using expert
                        advisors unrestricted access to its liquidity.
                        Oval Capital has positioned itself as the
                        forex broker of choice for traders worldwide.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container px-5 lg:py-10">
                <div className="lg:container lg:px-5">
                  <h2 className="text-lg text-bold text-dark">
                    <b>What we provide</b>
                  </h2>
                  <br />
                  <p className="leading-relaxed text-lg mb-4">
                    Oval Capital offers various accounts types,
                    trading software and tools to facilitate
                    individuals and institutional customers to trade
                    Forex and Derivatives online. All Retail,
                    Affiliates and White Label clients have the
                    opportunity to access various spreads and
                    liquidity via state of the art automated trading
                    platforms.
                  </p>
                  <p>
                    Oval Capital provides an unparalleled variety of
                    account options that clients can select to enjoy a
                    tailored trading experience that perfectly suits
                    their needs. Coupled with superior trading
                    conditions and lightning fast execution, Oval
                    Capital provides all the tools and services needed
                    for clients of any level to realise their trading
                    ambitions.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="feature-button-register mt-2">
            <ButtonGreen />
          </div>
          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
