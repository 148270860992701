import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AgentMinimumTrade() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-real-time">
            <div className="container px-5 py-5">
              <div className="text-gray-900 w-full border-2 border-gray-200 shadow-md bg-white rounded-md p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
                <div className="flex justify-center p-2 mb-2">
                  <img
                    src={require('../../assets/images/icons/agent-minimum-trade.jpg')}
                    alt="Real Time"
                    className="h-20"
                  />
                </div>

                <div className="text-center font-bold text-lg mb-4">
                  <h1>
                    Minimum Trading Time & No Rules you receive a
                    commission
                  </h1>
                </div>

                <p>
                  We are committed to paying your commission, even if
                  your client transaction is under 2 minutes we will
                  still pay it till 50%. We will not limit you in
                  receiving commissions, and we do not apply any rules
                  in the receipt of commissions on each agent.
                </p>
              </div>
            </div>
          </div>
          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
