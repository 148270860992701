import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import ButtonGreen from 'components/Button/ButtonGreen';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AboutRegulation() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-history">
            <section class="text-gray-700 body-font">
              <div className="container px-5 lg:py-10">
                <div className="lg:container lg:px-5">
                  <h1 className="text-lg text-bold text-black">
                    <b>About Us - Regulation</b>
                  </h1>
                </div>
              </div>

              <div class="container px-5 py-10 mx-auto flex">
                <div class="lg:w-12/12 mx-auto">
                  <div class="flex flex-col sm:flex-row">
                    <div class="sm:w-1/3 md:w-3/3 text-center sm:pr-8 sm:py-8">
                      <div class="flex flex-col items-center text-center justify-center">
                        <img
                          src={require('../../assets/images/logos/oval-capital-regulation.png')}
                          alt="Oval Capital International Small"
                          className="h-48"
                        />
                      </div>
                    </div>
                    <div class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-300 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-left sm:text-left">
                      <h2 className="text-lg text-bold text-dark">
                        <b>Oval Capital International</b>
                      </h2>
                      <br />
                      <p class="leading-relaxed text-lg mb-4">
                        <b>Oval Capital International</b> is a live
                        company incorporated in Hong Kong as a private
                        company limited with Registration No.: 2037708
                        Since 18 Februari 2014.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="feature-button-register mt-2">
            <ButtonGreen />
          </div>
          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
