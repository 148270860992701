import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavItem,
  Nav,
  Container,
} from 'reactstrap';

function IndexNavbar() {
  const [position, setPosition] = React.useState('');
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 99 ||
        document.body.scrollTop > 49
      ) {
        setPosition('sticky');
      } else if (
        document.documentElement.scrollTop < 100 ||
        document.body.scrollTop < 50
      ) {
        setPosition('sticky');
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  }, []);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={'top-0 z-50 bg-navbar ' + position}
        expand="lg"
      >
        <Container>
          <div className="navbar-translate">
            <Link
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-1 whitespace-no-wrap uppercase text-white"
              to="/"
            >
              <img
                src={require('../../assets/images/logos/oval-capital.png')}
                alt="Oval Capital International"
                className="h-8 lg:h-12 rounded-md"
              ></img>
            </Link>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <div className="lg:flex">
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    href="#pablo"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    {'About Us '}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to="/about/history" tag={Link}>
                      History
                    </DropdownItem>
                    <DropdownItem to="/about/regulation" tag={Link}>
                      Regulation
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    href="#pablo"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    {'For Trader '}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      to="/trader/cent-account"
                      tag={Link}
                    >
                      Cent Account
                    </DropdownItem>
                    <DropdownItem
                      to="/trader/standard-account"
                      tag={Link}
                    >
                      Standard Account
                    </DropdownItem>
                    <DropdownItem
                      to="/trader/comparison-account"
                      tag={Link}
                    >
                      Comparison Account
                    </DropdownItem>
                    <DropdownItem
                      to="/trader/contract-specification"
                      tag={Link}
                    >
                      Contract Specifications
                    </DropdownItem>
                    <DropdownItem
                      to="/trader/deposit-ways"
                      tag={Link}
                    >
                      Deposit Ways
                    </DropdownItem>
                    <DropdownItem
                      to="/trader/withdrawal-ways"
                      tag={Link}
                    >
                      Withdraw Ways
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    href="#pablo"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    {'For Agent '}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      to="/agent/regional-manager"
                      tag={Link}
                    >
                      Regional Manager
                    </DropdownItem>
                    <DropdownItem to="/agent/master-ib" tag={Link}>
                      Master IB
                    </DropdownItem>
                    <DropdownItem to="/agent/ib" tag={Link}>
                      IB
                    </DropdownItem>
                    <DropdownItem
                      to="/agent/multi-level-agent"
                      tag={Link}
                    >
                      Multi Level Agent Scheme
                    </DropdownItem>
                    <DropdownItem
                      to="/agent/realtime-commission"
                      tag={Link}
                    >
                      Real Time Commission
                    </DropdownItem>
                    <DropdownItem
                      to="/agent/minimum-trade-time"
                      tag={Link}
                    >
                      Minimum Trade Time
                    </DropdownItem>
                    <DropdownItem
                      to="/agent/room-dashboard"
                      tag={Link}
                    >
                      Agent Room Dashboard
                    </DropdownItem>
                    <DropdownItem
                      to="/agent/commission-report"
                      tag={Link}
                    >
                      Commision Report
                    </DropdownItem>
                    <DropdownItem
                      to="/agent/agent-client-report"
                      tag={Link}
                    >
                      Agent Client Report
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    href="#pablo"
                    nav
                    onClick={(e) => e.preventDefault()}
                  >
                    {'Platform '}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to="/platform/oval-capital-apps" tag={Link}>
                      MT4 PC
                    </DropdownItem>
                    <DropdownItem
                      to="/platform/oval-capital-apps"
                      tag={Link}
                    >
                      MT4 Mobile
                    </DropdownItem>
                    <DropdownItem
                      to="/platform/oval-capital-apps"
                      tag={Link}
                    >
                      Oval Capital Apps
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <div className="flex-grow lg:hidden" />
              <NavItem className="lg:hidden rounded-md mx-3 bg-gray-400">
                <div className="py-6 px-4 space-y-6">
                  <div className="flex justify-center">
                    <span className="w-full flex rounded-full shadow-sm justify-center items-center">
                      <a
                        href="."
                        className="rounded-full w-full flex items-center justify-center px-2 py-2 border border-transparent text-base leading-6 font-medium text-white focus:outline-none bg-inverse hover:bg-inverse-soft focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                      >
                        Register
                      </a>
                    </span>
                  </div>
                  <div className="flex flex-col justify-center">
                    <p className="text-center text-base leading-6 font-medium text-dark">
                      Already have an account?
                    </p>

                    <Link
                      to="."
                      className="focus:no-underline text-center text-base leading-6 font-medium text-dark"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </NavItem>
              <NavItem className="lg:hidden">
                <div className="py-6 px-5 space-y-6">
                  <div className="flex justify-center">
                    <div>
                      <div className="relative flex">
                        <svg
                          className="w-2 h-2 absolute top-0 right-0 m-3 pointer-events-none"
                          viewBox="0 0 412 232"
                        >
                          <path
                            d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                            fill="#648299"
                            fillRule="nonzero"
                          />
                        </svg>
                        <select className="text-dark bg-gray-400 border border-gray-300 rounded-full h-10 pl-5 pr-10 hover:border-gray-400 focus:outline-none appearance-none">
                          <option>English</option>
                          <option>Bahasa Indonesia</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </NavItem>
              <div className="h-12 lg:hidden" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
