import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function TraderDepositWays() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="feature-deposit-ways">
            <div className="container px-5 py-5">
              <div className="text-center">
                <h1 className="font-bold text-xl text-dark">
                  Deposit Ways
                </h1>
              </div>
            </div>

            <div className="container lg:px-5 lg:py-5">
              <section className="text-gray-700 body-font overflow-hidden">
                <div className="container lg:px-5 py-10 mx-auto">
                  <div className="flex flex-wrap -m-4 justify-center">
                    <MyCard
                      images="payment-visamastercard.png"
                      name="Visa, MasterCard"
                      currency="USD"
                      deposit="20 USD"
                    />
                    <MyCard
                      images="payment-mandiri.png"
                      images2="payment-bni.png"
                      images3="payment-bri.png"
                      images4="payment-bca.png"
                      name="Indonesian Local Bank"
                      currency="IDR"
                      deposit="20 USD"
                    />
                  </div>
                </div>
              </section>

              <section className="text-gray-700 body-font overflow-hidden">
                <div className="container lg:px-5 py-10 mx-auto">
                  <div className="flex flex-wrap -m-4 justify-center">
                    <MyCard
                      images="payment-fasapay.png"
                      name="Fasapay"
                      currency="USD, IDR"
                      deposit="5 USD"
                    />
                    <MyCard
                      images="payment-local.png"
                      name="Local Exchanger"
                      currency="IDR"
                      deposit="20 USD"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

const MyCard = ({
  images,
  name,
  currency,
  deposit,
  images2,
  images3,
  images4,
}) => {
  return (
    <div className="transform hover:-translate-y-1 hover:scale-101 p-4 xl:w-4/12 md:w-2/12 w-full">
      <div className="h-full p-4 rounded-lg border shadow-md flex flex-col relative overflow-hidden">
        <div className="flex justify-center">
          {images2 && images3 && images4 ? (
            <div className="h-32">
              <div className="h-15 flex justify-center">
                <img
                  src={require(`../../assets/images/logos/${images}`)}
                  alt="Visa Mastercard"
                  className="h-auto w-1/4"
                />
                <img
                  src={require(`../../assets/images/logos/${images2}`)}
                  alt="Visa Mastercard"
                  className="h-auto w-1/4"
                />
              </div>

              <div className="h-15 flex justify-center">
                <img
                  src={require(`../../assets/images/logos/${images3}`)}
                  alt="Visa Mastercard"
                  className="h-auto w-1/4"
                />
                <img
                  src={require(`../../assets/images/logos/${images4}`)}
                  alt="Visa Mastercard"
                  className="h-auto w-1/4"
                />
              </div>
            </div>
          ) : (
            <img
              src={require(`../../assets/images/logos/${images}`)}
              alt="Visa Mastercard"
              className="h-32"
            />
          )}
        </div>

        <div className="text-center">
          <h1>
            <b>{name}</b>
          </h1>
        </div>

        <hr className="my-3" />

        <div className="p-2">
          <table className="text-md rounded mb-4">
            <tbody>
              <tr className="w-full">
                <td className="p-1 px-1">Commission</td>
                <td className="p-1 px-1">: 0%</td>
              </tr>
              <tr className="w-full">
                <td className="p-1 px-1">Deposit Minimum</td>
                <td className="p-1 px-1">: {deposit}</td>
              </tr>
              <tr className="w-full">
                <td className="p-1 px-1">Currency</td>
                <td className="p-1 px-1">: {currency}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-full pt-3 items-center justify-center flex">
          <button className="hvr-shutter-out-horizontal mt-auto text-white bg-green-600 border-0 py-2 px-4 w-1/2 focus:outline-none rounded-full">
            Deposit
          </button>
        </div>

        <div className="flex justify-between pt-4">
          <p>Description</p>
          <p>Instructions</p>
        </div>
      </div>
    </div>
  );
};
