import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function TraderContractSpecification() {
  const [openTab, setOpenTab] = React.useState(1);
  const color = 'green';

  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="container py-5">
            <h1 className="font-bold text-xl text-dark">
              Contract Specifications
            </h1>
          </div>

          <div className="container px-5 py-5">
            <div className="flex flex-wrap">
              <div className="w-full justify-between">
                <ul
                  className="flex justify-between mb-0 list-none flex-wrap pt-3 pb-0 flex-row"
                  role="tablist"
                >
                  <li className="-mb-px mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-sm rounded block leading-normal ' +
                        (openTab === 1
                          ? 'text-white bg-' + color + '-600'
                          : 'text-' + color + '-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(1);
                      }}
                      data-toggle="tab"
                      href="#link1"
                      role="tablist"
                    >
                      <i className="fas fa-space-shuttle text-base mr-1"></i>{' '}
                      Forex
                    </a>
                  </li>
                  <li className="-mb-px mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-sm rounded block leading-normal ' +
                        (openTab === 2
                          ? 'text-white bg-' + color + '-600'
                          : 'text-' + color + '-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(2);
                      }}
                      data-toggle="tab"
                      href="#link2"
                      role="tablist"
                    >
                      <i className="fas fa-cog text-base mr-1"></i>{' '}
                      CFDs
                    </a>
                  </li>
                  <li className="-mb-px mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-sm rounded block leading-normal ' +
                        (openTab === 3
                          ? 'text-white bg-' + color + '-600'
                          : 'text-' + color + '-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(3);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      <i className="fas fa-briefcase text-base mr-1"></i>{' '}
                      Metal
                    </a>
                  </li>
                  <li className="-mb-px mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-sm rounded block leading-normal ' +
                        (openTab === 4
                          ? 'text-white bg-' + color + '-600'
                          : 'text-' + color + '-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(4);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      <i className="fas fa-briefcase text-base mr-1"></i>{' '}
                      Crypto
                    </a>
                  </li>
                  <li className="-mb-px mr-0 flex-auto text-center">
                    <a
                      className={
                        'text-xs font-bold uppercase px-5 py-3 shadow-sm rounded block leading-normal ' +
                        (openTab === 5
                          ? 'text-white bg-' + color + '-600'
                          : 'text-' + color + '-600 bg-white')
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenTab(5);
                      }}
                      data-toggle="tab"
                      href="#link3"
                      role="tablist"
                    >
                      <i className="fas fa-briefcase text-base mr-1"></i>{' '}
                      Equity
                    </a>
                  </li>
                </ul>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-sm rounded">
                  <div className="px-4 py-5 flex-auto">
                    <div className="tab-content tab-space">
                      <div
                        className={openTab === 1 ? 'block' : 'hidden'}
                        id="link1"
                      >
                        <ForexTable data={DataForex} />
                      </div>
                      <div
                        className={openTab === 2 ? 'block' : 'hidden'}
                        id="link2"
                      >
                        <ForexTable data={DataCFDs} />
                      </div>
                      <div
                        className={openTab === 3 ? 'block' : 'hidden'}
                        id="link3"
                      >
                        <ForexTable data={DataMetal} />
                      </div>
                      <div
                        className={openTab === 4 ? 'block' : 'hidden'}
                        id="link4"
                      >
                        <ForexTable data={DataCrypto} />
                      </div>
                      <div
                        className={openTab === 5 ? 'block' : 'hidden'}
                        id="link5"
                      >
                        <ForexTable data={DataEquity} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

const DataForex = [
  {
    symbol: 'AUDCAD',
    desc: 'Australian Dollar vs Canadian Dollar',
    minSpread: '1.8',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'AUDCHF',
    desc: 'Australian Dollar vs Swiss Franc',
    minSpread: '1.8',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'AUDJPY',
    desc: 'Australian Dollar vs Japanese Yen',
    minSpread: '2.2',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'AUDNZD',
    desc: 'Australian Dollar vs New Zealand Dollar',
    minSpread: '1.8',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'AUDUSD',
    desc: 'Australian Dollar vs US Dollar',
    minSpread: '2.2',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'CADCHF',
    desc: 'Canadian Dollar vs Swiss Franc',
    minSpread: '1.8',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'CADJPY',
    desc: 'Canadian Dollar vs Japanese Yen',
    minSpread: '1.6',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'CHFJPY',
    desc: 'Swiss Franc vs Japanese Yen',
    minSpread: '2.3',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'EURAUD',
    desc: 'Euro vs Australian Dollar',
    minSpread: '1.6',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'EURCAD',
    desc: 'Euro vs Canadian Dollar',
    minSpread: '2.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'EURCHF',
    desc: ' Euro vs Swiss Franc',
    minSpread: '1.8',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'EURGBP',
    desc: ' Euro vs Great British Pound',
    minSpread: '1.9',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
];

const DataMetal = [
  {
    symbol: 'XAUUSD',
    desc: 'Spot Gold 100 troy Oz',
    minSpread: '40.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'XAGUSD',
    desc: 'Spot Silver 5000 troy Oz',
    minSpread: '30.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
];

const DataCFDs = [
  {
    symbol: 'USOIL.',
    desc: 'Light Sweet Crude Oil 1000 Barrels Continuous Month',
    minSpread: '2.5',
    stopLevel: '1.5',
    swapShort: '0',
    swapLong: '0',
  },
];

const DataCrypto = [
  {
    symbol: 'BTCUSD',
    desc: 'Bit Coin vs USD',
    minSpread: '500.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'BCHUSD',
    desc: 'Bit Coin Cash vs USD',
    minSpread: '50.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'ETHUSD',
    desc: 'Etherium vs USD',
    minSpread: '50.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'LTCUSD',
    desc: 'Lite Coin vs USD',
    minSpread: '50.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'XRPUSD',
    desc: 'XRP vs USD',
    minSpread: '50.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
];

const DataEquity = [
  {
    symbol: 'DAX.SPOT',
    desc: 'DAX SPOT',
    minSpread: '40.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'DOW30',
    desc: 'Mini Dow Jones Continuous',
    minSpread: '30.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'DOW30.SPOT',
    desc: 'Mini Dow Jones Continuous',
    minSpread: '0.8',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'HSI.SPOT',
    desc: 'HSI SPOT',
    minSpread: '10.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'KOSPI',
    desc: 'Kospi',
    minSpread: '10.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'NASDAQ',
    desc: 'E-Mini Nasdaq 100 Continuous',
    minSpread: '40.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
  {
    symbol: 'NIKKEI.SPOT',
    desc: 'Nikkei.Spot',
    minSpread: '10.0',
    stopLevel: '0.5',
    swapShort: '0',
    swapLong: '0',
  },
];

const ForexTable = ({ data }) => {
  return (
    <table class="border-collapse w-full">
      <thead>
        <tr>
          <th class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
            Symbol
          </th>
          <th class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
            Description
          </th>
          <th class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
            Min. Spread
          </th>
          <th class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
            Stop Level
          </th>
          <th class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
            Swap Short
          </th>
          <th class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden lg:table-cell">
            Swap Long
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={index}
            class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          >
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-1 py-1 text-xs font-bold uppercase">
                Company name
              </span>
              {item.symbol}
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-1 py-1 text-xs font-bold uppercase">
                Country
              </span>
              {item.desc}
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 border border-b text-center block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-1 py-1 text-xs font-bold uppercase">
                Status
              </span>
              {item.minSpread}
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 border border-b text-center block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-1 py-1 text-xs font-bold uppercase">
                Actions
              </span>
              {item.stopLevel} pip
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 border border-b text-center block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-1 py-1 text-xs font-bold uppercase">
                Actions
              </span>
              {item.swapShort}
            </td>
            <td class="w-full lg:w-auto p-3 text-gray-800 border border-b text-center block lg:table-cell relative lg:static">
              <span class="lg:hidden absolute top-0 left-0 bg-blue-200 px-1 py-1 text-xs font-bold uppercase">
                Actions
              </span>
              {item.swapLong}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
