import React from 'react';

export default function TopNavbar({ fixed }) {
  return (
    <React.Fragment>
      <nav className="hidden lg:flex lg:flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-black">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <img
              src={require('../../assets/images/logos/oval-capital.png')}
              alt="Oval Capital International"
              className="w-40 lg:hidden"
            ></img>
          </div>

          <div
            className={
              'lg:flex flex-grow items-center py-0 sm:py-5 hidden'
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto py-0 sm:py-5">
              <li className="nav-item">
                <a
                  className="hover:no-underline px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#login"
                >
                  <i className="far fa-user text-lg leading-lg text-white opacity-75"></i>
                  <span className="ml-2">Login</span>
                </a>
              </li>
              <li className="nav-item">
                <button className="hvr-shutter-out-horizontal focus:outline-none font-bold bg-inverse hover:bg-tertiary text-white py-2 px-4 rounded-full">
                  Register
                </button>
              </li>
              <li className="nav-item hidden">
                <a
                  className="hover:no-underline px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75"
                  href="#help"
                >
                  <i className="far fa-question-circle text-lg leading-lg text-white opacity-75"></i>
                  <span className="ml-2">Help</span>
                </a>
              </li>
              <li className="nav-item">
                <hr />
              </li>
            </ul>
          </div>
        </div>

        {/* <div
          className={`${
            !navbarOpen ? 'hidden' : null
          } h-screen relative top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden min-h-full`}
        >
          <div className="rounded-lg shadow-lg z-50">
            <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 space-y-6 max-h-full h-auto">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-8 w-auto"
                      src={require('../../assets/images/logos/oval-capital.png')}
                      alt="Oval Capital International"
                    />
                  </div>
                  <div className="-mr-2">
                    <button
                      onClick={() => setNavbarOpen(false)}
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="grid row-gap-8">
                    <a
                      href="."
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                        />
                      </svg>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        About Us
                      </div>
                    </a>
                    <a
                      href="."
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                        />
                      </svg>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        For Trader
                      </div>
                    </a>
                    <a
                      href="."
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                      </svg>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        For Partnership
                      </div>
                    </a>
                    <a
                      href="."
                      className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
                    >
                      <svg
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                        />
                      </svg>
                      <div className="text-base leading-6 font-medium text-gray-900">
                        Platform
                      </div>
                    </a>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="flex justify-center">
                  <span className="w-50 flex rounded-full shadow-sm justify-center items-center">
                    <a
                      href="."
                      className="rounded-full w-full flex items-center justify-center px-2 py-2 border border-transparent text-base leading-6 font-medium text-white focus:outline-none bg-oranges hover:bg-orange-600 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                    >
                      Register
                    </a>
                  </span>
                </div>
                <div className="flex justify-center">
                  <p className="text-center text-base leading-6 font-medium text-gray-500">
                    {'Already have an account?'}
                    <a
                      href="."
                      className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                    >
                      {' Login'}
                    </a>
                  </p>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="flex justify-center">
                  <div>
                    <div className="relative flex">
                      <svg
                        className="w-2 h-2 absolute top-0 right-0 m-3 pointer-events-none"
                        viewBox="0 0 412 232"
                      >
                        <path
                          d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                          fill="#648299"
                          fillRule="nonzero"
                        />
                      </svg>
                      <select className="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
                        <option>English</option>
                        <option>Bahasa Indonesia</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className={`${!mobile ? "hidden" : null} h-screen w-full bg-white rounded-md`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <nav className="grid row-gap-12">
              <a
                href="."
                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
                <div className="text-base leading-6 font-medium text-gray-900">
                  About Us
                </div>
              </a>
              <a
                href="."
                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
                  />
                </svg>
                <div className="text-base leading-6 font-medium text-gray-900">
                  For Trader
                </div>
              </a>
              <a
                href="."
                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                <div className="text-base leading-6 font-medium text-gray-900">
                  For Partnership
                </div>
              </a>
              <a
                href="."
                className="-m-3 p-3 flex items-center space-x-3 rounded-md hover:bg-gray-50 transition ease-in-out duration-150"
              >
                <svg
                  className="flex-shrink-0 h-6 w-6 text-indigo-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <div className="text-base leading-6 font-medium text-gray-900">
                  Platform
                </div>
              </a>
            </nav>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="flex justify-center">
              <span className="w-50 flex rounded-full shadow-sm justify-center items-center">
                <a
                  href="."
                  className="rounded-full w-full flex items-center justify-center px-2 py-2 border border-transparent text-base leading-6 font-medium text-white focus:outline-none bg-oranges hover:bg-orange-600 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                >
                  Register
                </a>
              </span>
            </div>
            <div className="flex justify-center">
              <p className="text-center text-base leading-6 font-medium text-gray-500">
                {"Already have an account?"}
                <a
                  href="."
                  className="text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150"
                >
                  {" Login"}
                </a>
              </p>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div className="flex justify-center">
              <div>
                <div className="relative flex">
                  <svg
                    className="w-2 h-2 absolute top-0 right-0 m-3 pointer-events-none"
                    viewBox="0 0 412 232"
                  >
                    <path
                      d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z"
                      fill="#648299"
                      fillRule="nonzero"
                    />
                  </svg>
                  <select className="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
                    <option>English</option>
                    <option>Bahasa Indonesia</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </nav>
    </React.Fragment>
  );
}
