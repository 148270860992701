import React from "react";

export default function OurService() {
  return (
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-12 mx-auto">
        <div className="flex flex-wrap w-full mb-10 flex-col items-center text-center">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            Our Services
          </h1>
        </div>
        <div className="flex flex-wrap slide-top">
          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-300 p-6 rounded-lg">
              <div className="inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  src={require("../../assets/images/icons/service-deposit.png")}
                  alt="Deposit"
                  className="h-16 justify-center"
                />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Fast Deposit & Withdrawal
              </h2>
              <p className="leading-relaxed text-base">
                Deposit & Withdrawal processed 24 hours.
              </p>
            </div>
          </div>
          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-300 p-6 rounded-lg">
              <div className="inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  src={require("../../assets/images/icons/service-experience.png")}
                  alt="6 Years Experience"
                  className="h-16 justify-center"
                />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                6 Years in Market
              </h2>
              <p className="leading-relaxed text-base">
                We are have experience 6+ Years in Markets.
              </p>
            </div>
          </div>
          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-300 p-6 rounded-lg">
              <div className="inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  src={require("../../assets/images/icons/service-location.png")}
                  alt="International Company"
                  className="h-16 justify-center"
                />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Hongkong Company
              </h2>
              <p className="leading-relaxed text-base">
                Our Business Company Based in Hongkong.
              </p>
            </div>
          </div>
          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-300 p-6 rounded-lg">
              <div className="inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  src={require("../../assets/images/icons/service-level-system.png")}
                  alt="Multi Level System"
                  className="h-16 justify-center"
                />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Multi Level System
              </h2>
              <p className="leading-relaxed text-base">
                Multi-Level Agent system with the deep level.
              </p>
            </div>
          </div>
          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-300 p-6 rounded-lg">
              <div className="inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  src={require("../../assets/images/icons/service-copy-trading.png")}
                  alt="Social Trading"
                  className="h-16 justify-center"
                />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                Copy Trading/Social Trading
              </h2>
              <p className="leading-relaxed text-base">
                Choose your suitable fund-manager for your investment.
              </p>
            </div>
          </div>
          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out xl:w-1/3 md:w-1/2 p-4">
            <div className="border border-gray-300 p-6 rounded-lg">
              <div className="inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 mb-4">
                <img
                  src={require("../../assets/images/icons/service-account.png")}
                  alt="3 Type of Account"
                  className="h-16 justify-center"
                />
              </div>
              <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                3 Type of Account
              </h2>
              <p className="leading-relaxed text-base">
                Choose your suitable fund-manager for your investment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
