import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AgentRealtimeCommission() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-real-time">
            <div className="container px-5 py-5">
              <div className="text-gray-900 w-full border-2 border-gray-200 shadow-md bg-white rounded-md p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
                <div className="flex justify-center p-2 mb-2">
                  <img
                    src={require('../../assets/images/icons/agent-realtime-commission.jpg')}
                    alt="Real Time"
                    className="h-20"
                  />
                </div>

                <div className="text-center font-bold text-lg mb-4">
                  <h1>Real Time Commission with Three Depth Level</h1>
                </div>

                <p>
                  We pay the commission realtime after your client
                  trade is closed. we will pay all commission without
                  any retriction. We only apply standard rules, namely
                  a minimum open time of transactions from your
                  client, if the transaction is under 2 minutes we
                  will still pay you 50% of the total commission (10
                  USD / Lot).
                </p>
                <br />
                <p>
                  This real time commission payment system also
                  applies to multi-level clients, until the client is
                  below the IB level.
                </p>
                <br />
                <p>
                  <b>
                    Note : There will be no broken chains from the
                    agents below you even if the agents below you
                    level up with you or above you.
                  </b>
                </p>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
