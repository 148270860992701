import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AgentMasterIB() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-master-ib">
            <div className="container py-5 text-center">
              <h1 className="font-bold text-xl text-dark">
                Master IB
              </h1>
            </div>

            <div className="container px-5 py-5">
              <div className="text-gray-900 w-full bg-gray-300 rounded-md p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
                <p>
                  <b>Master IB</b> is a personal or institution that
                  already has a community network or partnership
                  network with IBs and clients. The Master IB together
                  with IBs build and develop Oval Capital with various
                  methods such as forming an educational community, a
                  community of master trades and other activities that
                  support the development of oval capital marketing.
                  The Master IB will get facilities that are no less
                  special than the Regional Manager, We present the
                  best and special facilities to support and support
                  the network development and clients of the master
                  IB.
                </p>

                <div className="flex justify-center align-middle">
                  <button className="mt-16 text-white bg-green-600 border-0 py-2 px-3 focus:outline-none hover:bg-indigo-600 rounded-md text-md">
                    Apply & Become Master IB
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
