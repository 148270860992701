import React from 'react';

export default function NotFound() {
  return (
    <React.Fragment>
      <style>
        {`.gradient { background-image: linear-gradient(135deg, #1e1e21 35%, #34346b 100%);},`}
      </style>

      <div class="gradient text-white min-h-screen flex items-center">
        <div class="container mx-auto p-4 flex flex-wrap items-center">
          <div class="w-full md:w-5/12 text-center p-4">
            <img
              src={require('../assets/images/logos/page-404.png')}
              alt="Not Found"
            />
          </div>
          <div class="w-full md:w-7/12 text-center md:text-left p-3">
            <div class="text-6xl font-medium">404</div>
            <div class="text-xl md:text-3xl font-medium mb-4">
              Oops. This page has gone missing.
            </div>
            <div class="text-lg mb-8">
              You may have mistyped the address or the page may have
              moved.
            </div>
            <a
              href="/"
              class="border border-white rounded p-2 hover:no-underline"
            >
              Go Home
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
