import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AgentIB() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-master-ib">
            <div className="container py-5 text-center">
              <h1 className="font-bold text-xl text-dark">IB</h1>
            </div>

            <div className="container px-5 py-5">
              <div className="text-gray-900 w-full bg-gray-300 rounded-md p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
                <p>
                  Do you have a customer network? and you want to
                  benefit if you continue to nurture and develop this
                  network of friends? Register to become an IB at
                  ovalcapital, and register your clients in your
                  network so that you will get realtime commission
                  from your client transactions. We share the
                  commission from the company's profit which is taken
                  from the spread, so this commission is legal for you
                  without reducing your client's finances at all. You
                  will get special facilities to manage your network.
                </p>

                <div className="flex justify-center align-middle">
                  <button className="mt-16 text-white bg-green-600 border-0 py-2 px-3 focus:outline-none hover:bg-indigo-600 rounded-md text-md">
                    Apply & Become IB
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
