import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function AgentRegionalManager() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-regional-manager">
            <div className="container py-5 text-center">
              <h1 className="font-bold text-xl text-dark">
                Regional Manager
              </h1>
            </div>

            <div className="container px-5 py-5">
              <div className="text-gray-900 w-full bg-gray-300 rounded-md p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
                <p>
                  <b>Regional Manager</b> is a person or institution
                  that represents Oval Capital in a certain coverage
                  area. The regional manager will cooperate fully with
                  oval capital. Regional managers will get more
                  benefits than other partner levels. And of course
                  will have VIP facilities to support the development
                  of the regional manager itself. We are very happy if
                  you are interested in becoming the regional manager
                  of oval capital. You can submit yourself to us to
                  become a regional manager, Of course, with the
                  experience and community network, clients that you
                  have built so far.
                </p>

                <div className="flex justify-center align-middle">
                  <button className="mt-16 text-white bg-green-600 border-0 py-2 px-3 focus:outline-none hover:bg-indigo-600 rounded-md text-md">
                    Apply Become Regional Manager
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
