import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

const MyData = [
  {
    name: '100% Payout',
    icon: 'minimum-trade-max.jpg',
    text_1:
      'Our Multi level Scheme is design with no retriction to get till 100% all level network ($10 USD).',
    text_2:
      'You can create your network with same data in all level and get your commission 100% payout from all level.',
  },
  {
    name: 'Real Time Commission',
    icon: 'real-time.jpg',
    text_1:
      'We pay the commission realtime after your client trade is closed. We will pay all commission without any retriction.',
    text_2:
      'We only apply standard rules, namely a minimum open time of transactions from your client, if the transaction is under 2 minutes we will still pay you 50% of the total commission (10 USD / Lot).',
  },
  {
    name: 'Multi Level Commission',
    icon: 'multi-level.jpg',
    text_1:
      'Our Multi Level Commission is designed till four level consisting of Regional Manager, Master IB, IB.',
    text_2:
      'The chain can continue to form down or sideways to infinity levels.',
  },
];

export default function AgentMultiLevel() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-master-ib">
            <div className="container py-5 text-center">
              <h1 className="font-bold text-xl text-dark">
                Multi Level Agent Scheme
              </h1>
            </div>

            <div className="container lg:px-5 lg:py-5">
              <div className="text-gray-700 body-font overflow-hidden">
                <div className="container lg:px-5 py-10 mx-auto">
                  <div className="flex flex-wrap -m-4 justify-center">
                    {MyData.map((item, index) => (
                      <MyCard
                        key={index}
                        images={item.icon}
                        name={item.name}
                        text_1={item.text_1}
                        text_2={item.text_2}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-master-ib">
            <div className="container py-5 text-center">
              <h1 className="font-bold text-xl text-dark">
                General Scheme
              </h1>
            </div>

            <div className="container lg:px-5 lg:py-5">
              <div className="text-gray-700 body-font overflow-hidden">
                <div className="container lg:px-5 py-2 mx-auto">
                  <div className="flex justify-center">
                    <img
                      src={require(`../../assets/images/logos/general-scheme.png`)}
                      alt="General Scheme"
                      className="h-auto"
                    />
                  </div>
                  <div className="w-full text-center py-4 text-dark">
                    <p>
                      <b>Note</b> : Transaction time below 2 minutes,
                      commission also will be paid 50%.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}

const MyCard = ({ images, name, text_1, text_2 }) => {
  return (
    <div className="transform hover:-translate-y-1 hover:scale-101 p-4 xl:w-4/12 md:w-2/12 w-full">
      <div className="h-full p-4 rounded-lg border shadow-md flex flex-col relative overflow-hidden">
        <div className="flex justify-center">
          <img
            src={require(`../../assets/images/icons/${images}`)}
            alt="Visa Mastercard"
            className="h-32"
          />
        </div>

        <div className="text-center text-lg">
          <h1>
            <b>{name}</b>
          </h1>
        </div>

        <hr className="my-3" />

        <div className="p-2">
          <p>{text_1}</p>
        </div>

        <div className="p-2">
          <p>{text_2}</p>
        </div>
      </div>
    </div>
  );
};
