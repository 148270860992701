import React from "react";

export default function PaymentList() {
  return (
    <section className="text-gray-700 body-font">
      <div className="container px-5 py-5 mx-auto">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="text-xl font-bold mb-2 text-dark">
            We Accept:
          </h1>
        </div>
        <div className="grid lg:grid-cols-7 grid-cols-4 md:grid-cols-4 gap-1">
          <BaseCard image="payment-visamastercard.png" text="VisaMasterCard" />
          <BaseCard image="payment-fasapay.png" text="Fasapay" />
          <BaseCard image="payment-bca.png" text="BCA" />
          <BaseCard image="payment-mandiri.png" text="Mandiri" />
          <BaseCard image="payment-bni.png" text="BNI" />
          <BaseCard image="payment-bri.png" text="BRI" />
          <BaseCard image="payment-local.png" text="Local Exchanger" />
        </div>
      </div>
    </section>
  );
}

function BaseCard({ image, text }) {
  return (
    <div className="flex justify-center items-center">
      <img
        src={require(`../../assets/images/logos/${image}`)}
        alt={text}
        className="lg:h-20 sm:h-5 h-10 justify-center"
      />
    </div>
  );
}
