import React from 'react';

export default function DefaultFooter() {
  return (
    <footer className="text-white bg-navbar">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-no-wrap flex-wrap flex-col">
        <div className="lg:divide-x lg:divide-gray-700 flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-left">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">
              About Us
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  History
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Regulation
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">
              For Trader
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Cent Account
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Standard Account
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Contract Specifications
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Deposit Ways
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Withdraw Ways
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">
              For Agent
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Regional Manager
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Master IB
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  IB
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Multi Level Agent Scheme
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Real Time Commision
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Minimum Trade Time
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Agent Room Dashboard
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Commision Report
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Agent Client Report
                </a>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">
              Platforms
            </h2>
            <nav className="list-none mb-10">
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  MT4 PC
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  MT4 Mobile
                </a>
              </li>
              <li>
                <a
                  href="."
                  className="text-gray-400 hover:text-inverse-soft hover:no-underline"
                >
                  Oval Capital Apps
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="section-text-footer">
        <div className="container mx-auto py-3 px-5 text-xs text-white">
          <p>
            <b>Legal :</b> Oval Capital International is a live
            company incorporated in Hong Kong as a private company
            limited with Registration No.: 2037708
          </p>
          <br />
          <p>
            <b>Risk Warning :</b> Trading Leveraged Products such as
            Forex and Derivatives may not be suitable for all
            investors as they carry a high degree of risk to your
            capital. Please ensure that you fully understand the risks
            involved, taking into account your investments objectives
            and level of experience, before trading, and if necessary,
            seek independent advice. Please read the full Risk
            Disclosure.
          </p>
          <br />
          <p>
            <b>Regional Restrictions :</b> Oval Capital International
            does not provide services to residents of the USA, Canada,
            Sudan, Israel, Syria, North Korea.
          </p>
        </div>
      </div>
      <div className="bg-black">
        <div className="container mx-auto py-3 px-5 flex justify-center text-center">
          <p>
            Copyright © 2014 - {new Date().getFullYear()} - All Right
            Reserved
          </p>
        </div>
      </div>
    </footer>
  );
}
