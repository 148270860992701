import React from 'react';

import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import Footer from 'components/Footers/DefaultFooter';

export default function TraderStandardAccount() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-cent-account">
            <div className="container py-5 text-center">
              <h1 className="font-bold text-xl text-dark">
                Standard Account
              </h1>
            </div>

            <div className="container px-5 py-5">
              <div className="text-gray-900 w-full bg-gray-300 rounded-md p-8 flex flex-col md:ml-auto mt-10 md:mt-0">
                <div className="text-lg text-bold text-dark">
                  <h1>
                    <b>Standard Account</b>
                  </h1>
                </div>
                <br />

                <p>
                  Standart Account accommodates the experienced retail
                  traders. A key feature is the flexibility in
                  position sizing. The limit for a single trade size
                  is Flexible lots base on request, by the default is
                  5 lots size. There is a minimum trade size of 0.01
                  lot and the trade size increment remains flexible at
                  0.01. The Premium Account uses the MetaTrader4 and
                  any of the available mobile trading platforms.
                </p>

                <div className="flex justify-center align-middle">
                  <button className="mt-16 text-white bg-green-600 border-0 py-2 px-3 focus:outline-none hover:bg-indigo-600 rounded-md text-md">
                    Open Account
                  </button>
                </div>
              </div>
            </div>

            <div className="container px-5 py-5">
              <div className="text-gray-900">
                <div className="p-2 flex">
                  <h1 className="text-xl">Account Type</h1>
                </div>
                <div className="px-3 py-4 flex justify-start">
                  <table className="w-2/3 text-md bg-white shadow-sm rounded mb-4">
                    <tbody>
                      <tr className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">Trading Platform</td>
                        <td className="p-3 px-5">
                          MetaTrader 4 Mobile Trading
                        </td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-200">
                        <td className="p-3 px-5">Spreads</td>
                        <td className="p-3 px-5">From 1 pip</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">Trading Instruments</td>
                        <td className="p-3 px-5">
                          All Available (Forex, CFDs, Metal, Crypto,
                          Equity)
                        </td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-200">
                        <td className="p-3 px-5">Fifth Decimal</td>
                        <td className="p-3 px-5">Yes</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">Maximum Leverage</td>
                        <td className="p-3 px-5">* 1: 400</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-200">
                        <td className="p-3 px-5">Execution</td>
                        <td className="p-3 px-5">Market Execution</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">
                          Minimum Opening Deposit
                        </td>
                        <td className="p-3 px-5">$1 USD</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-200">
                        <td className="p-3 px-5">Minimum trade size</td>
                        <td className="p-3 px-5">0.01 Cent Lot</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">Trade size increment</td>
                        <td className="p-3 px-5">0.01 Cent</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-200">
                        <td className="p-3 px-5">
                          Maximum Total Trade Size
                        </td>
                        <td className="p-3 px-5">5 Standard lots</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">
                          Margin Call / Stop out level
                        </td>
                        <td className="p-3 px-5">100% / 20%</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-200">
                        <td className="p-3 px-5">Account Currency</td>
                        <td className="p-3 px-5">USD</td>
                      </tr>
                      <tr className="border-b hover:bg-orange-100 bg-gray-100">
                        <td className="p-3 px-5">Commission</td>
                        <td className="p-3 px-5">No</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
