import React from 'react';

export default function HomeCard() {
  return (
    <section className="text-gray-700 body-font z-10 rounded-md">
      <div className="container px-5 py-5 mx-auto sm:px-2 sm:py-2 slide-top">
        <div className="grid grid-cols-1 gap-1 sm:grid-cols-3 ">
          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out h-full p-2 sm:p-5 text-center cursor-pointer rounded-md">
            <div className="py-5 sm:py-1 max-w-sm rounded overflow-hidden shadow-lg hover:bg-white transition duration-500  bg-white">
              <div className="space-y-2">
                <div className="flex justify-center">
                  <img
                    src={require('../../assets/images/icons/leverage.png')}
                    alt="Leverage"
                    className="h-16 justify-center"
                  />
                </div>

                <div className="px-6 py-2">
                  <div className="space-y-5">
                    <div className="font-bold text-xl mb-2">
                      Real Leverage 1:400
                    </div>
                    <p className="text-black">
                      Your leverage will not change even if your
                      balance increases, Market hectic and change of
                      day.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out h-full p-2 sm:p-5 text-center cursor-pointer rounded-md">
            <div className="py-5 sm:py-1 max-w-sm rounded overflow-hidden shadow-lg hover:bg-white bg-white transition duration-500">
              <div className="space-y-2">
                <div className="flex justify-center">
                  <img
                    src={require('../../assets/images/icons/execution.png')}
                    alt="Execution"
                    className="h-16 justify-center"
                  />
                </div>

                <div className="px-6 py-2">
                  <div className="space-y-5">
                    <div className="font-bold text-xl mb-2">
                      Market Execution 0.3 Second
                    </div>
                    <p className="text-black">
                      We provide real markets with execution time 0.3
                      second, less slip-page and delay.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="transform hover:-translate-y-1 hover:scale-101 transition duration-500 ease-in-out h-full p-2 sm:p-5 text-center cursor-pointer rounded-md">
            <div className="py-5 sm:py-1 max-w-sm rounded overflow-hidden shadow-lg hover:bg-white transition duration-500 bg-white ">
              <div className="space-y-2">
                <div className="flex justify-center">
                  <img
                    src={require('../../assets/images/icons/strategy.png')}
                    alt="Strategy"
                    className="h-16 justify-center"
                  />
                </div>

                <div className="px-6 py-2">
                  <div className="space-y-5">
                    <div className="font-bold text-xl mb-2">
                      All Strategy is Allowed
                    </div>
                    <p className="text-black">
                      We allowed all strategy of trading EA/Robot,
                      scalping and all your trading strategy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
