import Footer from 'components/Footers/DefaultFooter';
import FixedNavbar from 'components/Navbars/FixedNavbar';
import TopNavbar from 'components/Navbars/TopNavbar';
import PaymentList from 'components/Payment/PaymentList';
import React from 'react';


export default function PlatformOvalApps() {
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('landing-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);
  return (
    <React.Fragment>
      <div className="theme-dark">
        <TopNavbar />
        <FixedNavbar />

        <main>
          <div className="section-oval-apps">
            <div className="container py-5 text-center">
              <h1 className="font-bold text-xl text-dark">
                MT4 Platform Download
              </h1>
            </div>

            <div className="container py-5 sm:px-0">
              <img
                src={require(`../../assets/images/icons/metatrader4.jpg`)}
                alt="Metatrader 4"
                className="h-auto"
              />
            </div>

            <div className="container px-5 text-dark py-2">
              <p>
                We suggest that you access the world of trading using
                the MT4 trading terminal. The MT4 trading terminal is
                one of the most popular trading terminals in the world
                due to its versatility and convenience. By using this
                platform, you can open deals to buy and sell currency
                pairs on the Forex market, shares of major companies
                and futures contracts (CFD on futures).
              </p>
              <br />
              <p>
                Convenient built-in indicators will help you to
                quickly perform technical analysis of the market
                situation, the constant news flow allows you to always
                be aware of the global situation. The opportunity of
                trading with the help of advisors saves you from being
                in front of the computer screen all the time.One big
                plus about MetaTrader is the intuitive interface that
                allows you to adapt quickly to its basic functions.
                Furthermore, our detailed training system will help
                you. To start using the terminal now, you need to
                download and install MetaTrader on your computer.
              </p>
              <br />
              <p>
                Download the MetaTrader4 terminal and start learning
                about it through our education system and start
                earning on the Forex market!
              </p>
            </div>
          </div>

          <section className="text-gray-700 body-font">
            <div className="container mx-auto flex px-5 py-24 lg:flex-row flex-col justify-around">
              {/* <div className="lg:flex-grow md:w-2/2 flex flex-col md:items-center md:text-left mb-16 md:mb-0 items-center"> */}
                {/* <div className="flex flex-col lg:flex-row justify-around"> */}
                  <a href="https://download.mql5.com/cdn/web/oval.inc/mt4/ovalinc4setup.exe" target="_blank" rel="noopener noreferrer">
                  <button className="my-4 bg-gray-200 inline-flex sm:py-2 sm:px-2 py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none">
                  <img
                    alt="svgImg"
                    className="mr-4 w-50 bg-dark"
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMzIiIGhlaWdodD0iMzIiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTcxLjY2NjY3LDg5LjY3NjVsLTUzLjcxMDU4LC0wLjA5MzE3bDAuMDAzNTgsNDUuNjY2bDUzLjcwNyw3LjQzMTgzek03OC44MzMzMyw4OS42OTA4M3Y1My45ODI5Mmw3NS4yMzIwOCwxMC40MDk1OGwwLjAxNzkyLC02NC4yNjM1ek03MS42NjY2NywyOS41ODRsLTUzLjc1LDcuNjExbDAuMDUwMTcsNDUuMjIxNjdoNTMuNjk5ODN6TTc4LjgzMzMzLDI4LjU2OTkydjUzLjg0Njc1aDc1LjIzMjA4di02NC41eiI+PC9wYXRoPjwvZz48L2c+PC9zdmc+"
                />
                    <span className="ml-4 flex items-start flex-col leading-none">
                      <span className="text-xs text-gray-600 mb-1">
                        GET IT ON
                      </span>
                      <span className="title-font font-medium">
                        Windows
                      </span>
                    </span>
                  </button>
                  </a>
                  <a href="https://download.mql5.com/cdn/mobile/mt4/android?server=OvalInc-Live" target="_blank" rel="noopener noreferrer">
                  <button className="my-4 bg-gray-200 inline-flex sm:py-2 sm:px-2 py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="w-6 h-6"
                      viewBox="0 0 512 512"
                    >
                      <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
                    </svg>
                    <span className="ml-4 flex items-start flex-col leading-none">
                      <span className="text-xs text-gray-600 mb-1">
                        GET IT ON
                      </span>
                      <span className="title-font font-medium">
                        Google Play
                      </span>
                    </span>
                  </button>
                  </a>
                  <a href="https://download.mql5.com/cdn/mobile/mt4/android?server=OvalInc-Live" target="_blank" rel="noopener noreferrer">
                  <button className="my-4 bg-gray-200 inline-flex sm:py-2 sm:px-2 py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="w-6 h-6"
                      viewBox="0 0 305 305"
                    >
                      <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
                      <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
                    </svg>
                    <span className="ml-4 flex items-start flex-col leading-none">
                      <span className="text-xs text-gray-600 mb-1">
                        Download on the
                      </span>
                      <span className="title-font font-medium">
                        App Store
                      </span>
                    </span>
                  </button>
                  </a>
                {/* </div> */}
              {/* </div> */}
            </div>
          </section>

          <div className="feature-payment-list">
            <PaymentList />
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  );
}
